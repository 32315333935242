import React from 'react'
import Pic1 from "./event images/6.jpeg"
import Pic2 from "./event images/3.jpeg"
import Pic3 from "./event images/4.jpeg"
import Pic4 from "./event images/5.jpeg"

function tree_growing() {
    return (
        <div className='ground'>
            <h1>FIRST RESPONDERS TEAM ON GROUND</h1>
            <img src={Pic1} alt='img' /><br /> <br />
            <p>We conducted a wild water rescue at Oldonyo Sabuk.</p>
            <img className='images' src={Pic2} alt='img'/><br /> <br />
            <img src={Pic3} alt='img'/><br /> <br />
            <img className='images' src={Pic4} alt='img'/>
            </div>
    )
}

export default tree_growing