import React from 'react'
import talking from './event images/IMG-20231102-WA0101.jpg'
import train from './event images/IMG-20231102-WA0085.jpg'
import train2 from './event images/IMG-20231102-WA0045.jpg'
import train3 from './event images/IMG-20231102-WA0098.jpg'

function lecture() {
  return (
    <div className='lecture'>
      <h1>A DISCUSSION ON THE NEED OF THE FIRST RESPONDERS SUPPORT NETWORK</h1>
      <img src={talking} alt='logo' />
      <p>This is an organization dedicated to addressing the unique needs and challenges faced by our first responders.</p>
      <img src={train} alt='' />
      <p>"We are creating a platform where the first responders can share their voice, hearts, and passion for serving and letting other first responders know that it is okay not to be okay.</p>
      <img src={train2} alt='' />
      <p>An organization such as '<b className='bold'>First Responders Support Network'</b> is therefore necessary to advocate for first responders wellness initiative, policies and legislations.</p>
      <img src={train3} alt='' />
      <p>Having a strong membership will ensure a united front to push for standards, frameworks, policies and legislations that recognize and promote the well-being of first responders and their families to be set, made, passed, and implemented."<br />
        <b className='bold'>Mr Mohamed Shahid Moughal,<br />
          Advisor, Public Risk Management and Oversight,<br />Office Of the Governor, Machakos County.</b></p>
    </div>
  )
}

export default lecture