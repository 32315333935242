import React from 'react'
import logo from '../LOGO3.png'

function about() {
  return (
    <div className='about'>
      <img className='Logo1' src={logo} alt='logo' />
      <h1>Mission</h1>
      <div className='mission'>
        <p>The mission of the First Responder Support Network is to provide comprehensive support including, research in modernizing working environments with safety precautions being paramount. Collaborating, partnering, providing guidance to government agencies and private enterprises towards equipping and training first responder units to international standards, provide research based knowledge on disaster risk management strategy, organize trainings in various disaster mitigation practices and resources to first responders, recognizing the unique challenges they face in their roles as emergency service providers.Our mission is rooted in the belief that first responders deserve dedicated support to enhance their well-being and promote their mental health.</p>
        <ol className='lists reveal' type='1'>
          <li><i>Support:</i> We aim to offer a strong support system for first responders, acknowledging the physical, emotional, and psychological toll of their work. We provide a safe and confidential space where they can seek assistance, share experiences, and find comfort among their peers.</li>
          <li><i>Resources:</i> We strive to offer a wide range of resources tailored to the specific needs of first responders. This includes access to mental health services, counseling, trauma-informed care, and educational materials to promote resilience and well-being.</li>
          <li><i>Education:</i> We are committed to raising awareness and understanding of the unique challenges faced by first responders. Through educational programs, workshops, and training, we aim to enhance knowledge, promote self-care, and develop skills to cope with stress and trauma.</li>
          <li><i>Retreats:</i> We organize retreats and therapeutic programs designed specifically for first responders. These retreats offer a respite from the demands of their profession and provide opportunities for healing, peer support, and personal growth.</li>
          <li><i>Volunteerism:</i> We encourage individuals from all backgrounds to contribute their time, skills, and resources to support first responders. Our volunteer program provides opportunities for individuals to make a positive impact and give back to those who serve and protect our communities.</li>
          <li><i>Donations:</i> We rely on the generosity of individuals, organizations, and businesses to sustain our programs and services. Donations allow us to continue providing vital support to first responders and expand our reach to help as many individuals as possible.</li>
          <li><i>Collaboration:</i> We actively collaborate with other organizations, agencies, and stakeholders in the first responder community. By fostering partnerships and sharing knowledge, we aim to create a united front in addressing the unique needs of first responders and promoting a culture of support and understanding.</li>
          <li><i>Technology:</i> To establish a new approach for disaster response in order to improve disaster resilience. This will be accomplished by the targeted employment and synergetic deployment of a set of appropriate and complementary technologies.</li>
        </ol>
      </div>
    </div>
  )
}

export default about