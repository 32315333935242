import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Avatar from './LOGO.png'
import './App.css';
import './App.scss'
import Home from './components/homepage';
import About from './components/about';
import Navbar from './components/Navbar';
import Startpage from './components/startpage';
import Donate from './components/donate';
import Education from './components/education';
import Retreat from './components/retreats';
import Volunteer from './components/volunteer';
import Error from './components/errorpage';
import Videos from './components/videos';
import Publications from './components/publications';
import Faq from './components/faq';
import Background from './components/background';
import Articles from './components/books_and_articles';
import Tac from './components/tac';
import Servicesoffered from './components/services offered';
import Partner from './components/partner';
import Coming from './components/coming_soon';
import Training from './components/events/training';
import Talking from './components/events/lecture'
import Sensitization from './components/events/sensitization'
import TreeGrowing from './components/events/tree_growing'
import Speech from './components/speech';
import Ground from './components/events/ground';
import Athi from './components/events/athiresponse';
import TreeDay from './components/events/tree_day';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <FloatingWhatsApp
          phoneNumber="+254704215539"
          accountName="First Responders Support Network"
          statusMessage='I am a first responder, are you?'
          chatboxHeight={350}
          chatboxClassName='chatbox'
          avatar={Avatar}
          buttonClassName='floating'
          allowEsc
          allowClickAway
        />
        <Startpage />
        <Navbar />
        <hr />
        <div className='bg'>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/background' element={<Background />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/donate' element={<Donate />} />
            <Route exact path='/education' element={<Education />} />
            <Route exact path='/retreat' element={<Retreat />} />
            <Route exact path='/volunteer' element={<Volunteer />} />
            <Route exact path='/books_and_articles' element={<Articles />} />
            <Route exact path='/publications' element={<Publications />} />
            <Route exact path='/videos' element={<Videos />} />
            <Route exact path='/faqs' element={<Faq />} />
            <Route exact path='/technology_and_communication' element={<Tac />} />
            <Route exact path='/services' element={<Servicesoffered />} />
            <Route exact path='/partner_with_us' element={<Partner />} />
            <Route exact path='/coming_soon' element={<Coming />} />
            <Route exact path='/training' element={<Training />} />
            <Route exact path='/lecture' element={<Talking />} />
            <Route exact path='/sensitizing_the_public' element={<Sensitization />} />
            <Route exact path='/tree_growing' element={<TreeGrowing />} />
            <Route exact path='/speeches' element={<Speech />} />
            <Route exact path='/first_responders_team_on_the_ground' element={<Ground />} />
            <Route exact path='/multi_agency_responce_at_Athi-River' element={<Athi />} />
            <Route exact path='/national_tree_planting_day' element={<TreeDay />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
