import React from 'react'

function Faq() {
    return (
        <div className='faq'>
            <div className='questions'>
                <details>
                    <summary className='q'>Who are first responders?</summary>
                    <p className='a'>First responders are individuals who are trained and equipped to provide immediate assistance and care during emergencies, disasters, and other critical situations. They are typically the first to arrive at the scene and are responsible for assessing the situation, stabilizing victims, and providing medical treatment until more specialized help arrives.
                    </p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>What are some examples of first responders?</summary>
                    <p className='a'>Examples of first responders include paramedics, emergency medical technicians (EMTs), firefighters, police officers, search and rescue teams, lifeguards, and certain types of healthcare professionals such as doctors and nurses who respond to emergencies outside of healthcare facilities.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>What is the role of first responders?</summary>
                    <p className='a'>The role of first responders is to provide rapid and skilled assistance during emergencies. Their responsibilities include assessing and managing the scene, administering immediate medical care, extricating trapped individuals, extinguishing fires, ensuring public safety, conducting search and rescue operations, and providing emotional support to victims and their families.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>What skills and training do first responders have?</summary>
                    <p className='a'>First responders undergo specialized training to develop skills in emergency response, medical care, search and rescue techniques, firefighting, crisis management, and other relevant areas. They are trained to assess and address a wide range of emergencies, handle medical equipment, perform CPR, control bleeding, provide basic life support, and operate specialized tools and vehicles.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>What are some challenges faced by first responders?</summary>
                    <p className='a'>First responders often face numerous challenges in their line of work. These challenges may include exposure to hazardous environments, physical and emotional stress, unpredictable situations, long working hours, trauma exposure, limited resources, and the need to make quick decisions under pressure.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>How do first responders coordinate with other emergency services?</summary>
                    <p className='a'>First responders often work collaboratively with other emergency services to ensure an effective and coordinated response. They communicate with police, fire departments, emergency medical services (EMS), hospitals, dispatch centers, and other relevant agencies to share information, allocate resources, and coordinate their actions on the ground.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>Are first responders subject to any legal protections?</summary>
                    <p className='a'>In many jurisdictions, first responders have legal protections that may vary based on local laws and regulations. These protections may include certain immunities from liability when performing their duties in good faith, as well as access to workers' compensation, disability benefits, and mental health support programs.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>How do first responders take care of their own well-being?</summary>
                    <p className='a'>First responders are trained to prioritize their own well-being to ensure they can effectively perform their duties. They receive training on self-care techniques, stress management, and psychological resilience. Many organizations have implemented support programs, counseling services, peer support groups, and regular debriefings to address the unique challenges and potential psychological impact of their work.</p>
                </details>
            </div>
            <div className='questions'>
                <details>
                    <summary className='q'>Can I become a first responder as a volunteer?</summary>
                    <p className='a'>Yes! There are opportunities to become a first responder as a volunteer. Many organizations, such as volunteer fire departments, search and rescue teams, and community emergency response teams (CERT), offer training programs for individuals interested in volunteering their time and skills during emergencies. These volunteers often work alongside and support professional first responders.</p>
                </details>
            </div>
        </div>
    )
}

export default Faq