import React from 'react'

function Volunteer() {
  return (
    <div>
      <h1>Volunteering</h1>
      <div className='volunteer'>
        <p>Remember that volunteering as a first responder requires dedication, commitment, and a willingness to undergo training and continuous learning. Overall, the importance of volunteering as a first responder lies in the critical support it provides to emergency response efforts, the positive impact on individuals and communities, and the cultivation of a culture of service and preparedness</p>
        <div className='questions'>
          <details>
            <summary className='q'>What are some benefits of volunteering as a first responder?</summary>
            <p className='a'>Volunteering as a first responder can offer numerous benefits, including:</p>
            <ol type='1.' className='a'>
              <li className='a'>Making a difference: Volunteering allows you to directly contribute to your community's well-being and help those in need during emergencies.</li>
              <li className='a'>Skill development: Volunteering provides an opportunity to learn new skills related to emergency response, such as first aid, CPR, search and rescue techniques, or fire safety.</li>
              <li className='a'>Personal growth: Volunteering can enhance personal growth by developing qualities such as resilience, problem-solving, teamwork, and leadership</li>
              <li className='a'>Building relationships: Volunteering allows you to form connections and build relationships with like-minded individuals, both within the first responder community and the community at large.</li>
              <li className='a'>Networking opportunities: Volunteering can provide networking opportunities within the field of emergency response, which may be beneficial if you're considering a career in this area or related fields.</li>
              <li className='a'>Increased self-confidence: Volunteering in challenging situations can boost your self-confidence and sense of accomplishment, knowing that you are making a positive impact.</li>
              <li className='a'>Learning about emergency preparedness: Volunteering as a first responder exposes you to valuable knowledge and practices related to emergency preparedness, which can be applied in your personal life as well.</li>
            </ol>
          </details>
        </div>
        <div className='questions'>
          <details>
            <summary className='q'>What are some responsibilities of volunteer first responders?</summary>
            <p className='a'>The responsibilities of volunteer first responders can vary depending on the organization and the specific role assigned. Some common responsibilities may include:</p>
            <ol type='1.' className='a'>
              <li className='a'>Participating in training and continuing education programs to maintain and enhance skills.</li>
              <li className='a'>Being available for scheduled shifts or on-call duty, as agreed upon with the organization.</li>
              <li className='a'>Assisting professional first responders during emergency situations, following established protocols and guidelines.</li>
              <li className='a'>Providing immediate medical care, if trained as an EMT or paramedic, until professional help arrives.</li>
              <li className='a'>Assisting with search and rescue operations, crowd control, or traffic management during emergencies or events.</li>
              <li className='a'> Supporting community education and outreach efforts related to emergency preparedness and safety.</li>
              <li className='a'>Adhering to the organization's code of conduct, maintaining professionalism, and respecting confidentiality.</li>
            </ol>
          </details>
        </div>
      </div>
    </div>
  )
}

export default Volunteer