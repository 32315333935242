import React from 'react'
import Speech from '../Shahid Speech.mp4'

function speech() {
  return (
    <div className='lecture'>
      <h1>A SPEECH ON THE MULTI-AGENCY RESPONSE AT ATHI-RIVER</h1>
      <video src={Speech} alt='logo' controls = "controls"/>
      <p>
        <b className='bold'>Mr Mohamed Shahid Moughal,<br />
          Advisor, Public Risk Management and Oversight,<br />Office Of the Governor, Machakos County.</b></p>
    </div>
  )
}

export default speech