import React from 'react'
import Img from './events/event images/IMG-20231102-WA0101.jpg'

function Education() {
  return (
    <div className='edu'>
      <h1>Education and Training</h1>
      <img src={Img} alt='img'></img>
      <p className='education'>Education is a crucial aspect of volunteering as a first responder. It encompasses both formal training and ongoing learning to ensure volunteers possess the necessary knowledge and skills to effectively carry out their roles. Here are some key points about education in the context of volunteering as a first responder:</p>
      <ol type='1' className='education'>
        <li>Initial training: We provide comprehensive initial training programs. These programs cover essential topics such as basic life support (BLS), first aid, CPR, incident command systems (ICS), disaster response protocols, search and rescue techniques, and other relevant skills and knowledge specific to the organization and the role of the volunteer.</li>
        <li>Certification and licensure: Depending on the specific responsibilities assigned, volunteers may be required to obtain certifications or licenses. For example, volunteers working in medical roles may need to be certified as emergency medical technicians (EMTs) or paramedics. We facilitate and sponsor the necessary training and certification processes.</li>
        <li>Collaboration with professional first responders: Education in the context of volunteering often involves collaborating with professional first responders, who can provide valuable insights and expertise. Volunteers benefit from learning alongside experienced professionals and gain practical knowledge from their real-world experiences.</li>
        <li>Personal development and growth: Education in the realm of volunteering as a first responder contributes to personal development and growth for volunteers. It expands their knowledge base, enhances their critical thinking and problem-solving abilities, and improves their overall competency. The skills and knowledge acquired through education can also be transferable to other areas of life and future career opportunities.</li>
      </ol>
      <p className='education'>Education plays a vital role in equipping first responder volunteers with the necessary competencies to effectively respond to emergencies, ensure their safety, and deliver quality assistance to those in need. It fosters professionalism, enhances the quality of service, and strengthens the overall effectiveness of volunteer-based emergency response efforts.</p>
    </div>
  )
}

export default Education