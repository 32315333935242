import React from 'react'
import Pic1 from "./event images/NPD1.jpeg"
import Growing from "./event images/IMG-20231113-WA0010.jpg"
import Pic3 from "./event images/NPD3.jpeg"
import Pic4 from "./event images/NPD4.jpeg"

function tree_day() {
    return (
        <div className='tree_day'>
            <h1>NATIONAL TREE PLANTING DAY</h1>
            <p>On this solemn <b className='bold'>National Tree Planting Day</b>, we pause to reflect and honor those who have tragically lost their lives in the ongoing MAM rains and  flood disasters. In a unified moment of silence, we express our deepest sympathies for the departed and extend our heartfelt gratitude to the valiant First Responders.<br/>
            <img src={Pic1} alt='img' /><br />
                As we bow our heads, we invoke divine blessings for the safety and well-being of these guardians of humanity. We pray for their continued strength, divine protection, and guidance as they carry out their noble duties. In commemoration of the lives affected by this calamity, we are called upon to plant trees. These trees stand as living monuments of resilience and hope, mirroring our collective determination to overcome adversity and emerge stronger.
                <img src={Growing} alt='img' /><br /> Their unwavering courage and selfless acts of heroism in the face of peril embody the very essence of service and sacrifice.<br />

                Together, we face these trying times with solidarity and compassion. Let us support one another and seek solace in the belief that providence will guide us through.<br />
                <img src={Pic3} alt='img' /><br />

                May each tree planted today flourish, just as our resolve to support each other and heal as a community does. May you be blessed, and may the trees we plant serve as a testament to our resilience and unity.<br/>

                <b className='bold'>Reminder</b>: Plant a Tree today. 🌳</p>
                <img src={Pic4} alt='img' /><br /><br/>
        </div>
    )
}

export default tree_day