import React from 'react'
import image1 from './event images/IMG-20231109-WA0021.jpg'
import image2 from './event images/IMG-20231109-WA0022.jpg'
import image3 from './event images/IMG-20231109-WA0023.jpg'
import image4 from './event images/IMG-20231109-WA0004.jpg'

function sensitization() {
    return (
        <div className='sensitization'>
            <h1>SENSITIZING THE PUBLIC ON COUNTY EMERGENCY NUMBERS IN CASE OF AN EMERGENCY</h1>
            <img src={image1} alt='img' />
            <p>Kithimani Yatta Emergency Numbers are:<br /><a href='tel:+254715068861'>Hotline 1</a><br /><a href='tel:+254720808900'>Hotline 2</a><br /><a href='tel: 1199'>Kenya Red Cross Society</a></p>
            <img src={image4} alt='img' /><br /> <br />
            <img src={image2} alt='img' /><br /> <br />
            <img src={image3} alt='img' />
            <p>Incident Commander giving instruction on how to unblock a road that was blocked following a tree fall due to heavy rains, cutting communication between Yatta and Mwala sub-counties, just before Athi River bridge.</p>
        </div>
    )
}

export default sensitization