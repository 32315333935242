import React from 'react'
import Download from '../downloadicon.png'
import Bpcfr from '../best_practices_for_counseling_first_responder (1).pdf'
import Ds from '../depression-know-the-signs.pdf'
import Eres from '../emergency_responder_exhaustion_syndrome_5-1-06.pdf'
import Epr from '../EPR_FirstResponder_web.pdf'
import Frg from '../First Responder Guidance June 2015 FINAL 2.pdf'
import Hfh from '../haven-from-the-horrors.pdf'
import Irtpe from '../intensive_residential_treatment_program_efficacy_for_emergency_responders’_critical_incident_stress.pdf'
import Ptsd from '../ptsd_checklist_and_scoring.pdf'


function publications() {
  return (
    <div className='publications'>
      <div className='publication'>
        <a className='publ' href={Ptsd} download={'PTSD checklist and scoring'} ><img src={Download} alt='new'></img> PTSD checklist and scoring<button>Download</button></a>
        <a className='link' href='https://forms.gle/Wcih29L6rpm3EKxN7'>PTSD CHECKLIST-Civilian Version</a>
      </div>
      <div className='publication'>
        <a className='publ' href={Bpcfr} download={'Best way for Counseling First Responders'} ><img src={Download} alt='new'></img>Best way for Counseling First Responders <button>Download</button></a>
      </div>
      <div className='publication'>
        <a className='publ' href={Ds} download={'Know the Signs of DEPRESSION'} ><img src={Download} alt='new'></img>Signs of Depression <button>Download</button></a>
      </div>
      <div className='publication'>
        <a className='publ' href={Eres} download={'Emergency Responder Exhaustion Syndrome'} ><img src={Download} alt='new'></img>Emergency Responder Exhaustion Syndrome <button>Download</button></a>
      </div>
      <div className='publication'>
        <a className='publ' href={Epr} download={'EPR First Responder'} ><img src={Download} alt='new'></img>EPR_First_Responder <button>Download</button></a>
      </div>
      <div className='publication'>
        <a className='publ' href={Frg} download={'First Responder Guidance- June 2015'} > <img src={Download} alt='new'></img>First Responder Guidance <button>Download</button></a>
      </div>
      <div className='publication'>
        <a className='publ' href={Hfh} download={'Haven From the Horrors'} ><img src={Download} alt='new'></img>Haven from the Horrors <button>Download</button></a>
      </div>
      <div className='publication'>
        <a className='publ' href={Irtpe} download={'Intensive Residential Treatment Program Efficiency for Emergency Responders'} ><img src={Download} alt='new'></img>Intensive Residential Treatment Program Efficiency for Emergency Responders <button>Download</button></a>
      </div>
    </div>
  )
}

export default publications