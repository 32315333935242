import React from 'react'

function videos() {
  return (
    <div className='videos'>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/SsWfDmVhdHE" title="First Responders and Mental Health: Supporting Our Heroes" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/tcISX0yetto" title="&quot;SIGNS&quot;- Short Film - A First Responders Battle" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/bvMuhmjcxNo" title="FIRST RESPONSE - Short Film (2016)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/1TfDMOVciD8" title="First Responder Motivation - “Rise Up”" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/md9IUgLtBI0" title="RESILIENT: Suicide/PTSD Inspirational Video (First Responder Tribute)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/lCs0FOcdQUw" title="Firefighter Tribute | &#39;Bleeding Out&#39;" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/pIGmLv6plqk" title="Not EVERYONE Goes Home - Firefighter Tribute" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/VnaG7-gn_aU" title="&quot;Men are useless&quot; - Firefighter motivation [Till I Colapse]" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/7vMGCHIu1ww" title="A Firefighters Tribute “Unstoppable”" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/knhHzvESQ_M" title="The First Responder Mindset: The Life You Save May be Your Own | Sean Gibbons | TEDxOshkosh" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/8Mk6ZZwfCkQ" title="First Responders Movie - Full Theatrical Trailer (HD)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/0xcxPnfBDXk" title="“Born For This” - Firefighter Motivation" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/g9TWhLzndtk" title="EMS Tribute | Born For This" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/uqPHGFBeZGE" title="David Carroll-everyday heros (police/fire/ems tribute)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/CVg7s7O3-Zo" title="&quot;Rise Up&quot; || Police Tribute (Return)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/rMFQv03dS4o" title="Police Motivation - Unstoppable - The Score" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <iframe width="300" height="250" src="https://www.youtube.com/embed/Zm2oUbC99Vk" title="&quot;Thunder&quot; Police Motivational Speech &amp; music video." allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
  )
}

export default videos