import React from 'react'
import Coming from '../Coming Soon.gif'

function Coming_soon() {
  return (
    <div className='coming'>
      <img src={Coming} alt='soon'></img>
    </div>
  )
}

export default Coming_soon