import React from 'react'
import Lipa from '../mpesa image.png'

function services_offered() {
    return (
        <div className='services'>
            <ul className='serve'>
                <li>Supply new installation fire extinguishers</li>
                <li>Service existing fire extinguishers and fire water reticulation, hose heels and sprinkler systems</li>
                <li>Install new fire water reticulation, hose reels and sprinkler systems</li>
                <li>Assessment of firms for capability and quality of its fire and safety products</li>
                <li>Consultancy</li>
                <li>Emergency Medical Technician (EMT) Training</li>
                <li>Occupational safety and Health (OSH) Training</li>
                <li>Fire Safety Training</li>
                <li>Basic Life Support (BLS) Training</li>
                <li>Pediatric First Aid Training</li>
            </ul><br /><br />
            <p className=' service bold'>Want to become a Member?</p>
            <div className="demo">
                <div className="containerr">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable light">
                                <h3 className="title">SINGLE MEMBERSHIP</h3>
                                <div className="price-value">
                                    <span className="currency">Ksh</span>
                                    <span className="amount">1,000.00</span>
                                    <span className="year">/year</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable">
                                <h3 className="title">SMALL ENTERPRISE</h3>
                                <div className="price-value">
                                    <span className="currency">Ksh</span>
                                    <span className="amount">2,000.00</span>
                                    <span className="year">/year</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable pink">
                                <h3 className="title">MEDIUM ENTERPRISES</h3>
                                <div className="price-value">
                                    <span className="currency">Ksh</span>
                                    <span className="amount">5,000.00</span>
                                    <span className="year">/year</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable blue">
                                <h3 className="title">COOPERATES</h3>
                                <div className="price-value">
                                    <span className="currency">Ksh</span>
                                    <span className="amount">10,000.00</span>
                                    <span className="year">/year</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 move">
                            <div className="pricingTable dark">
                                <h3 className="title">LIFE MEMBER</h3>
                                <div className="price-value">
                                    <span className="currency">Ksh</span>
                                    <span className="amount">500,000.00</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="lipa">
                                <p className='bolds'>How to Pay</p>
                                <img className='lipaimage' src={Lipa} alt='lipa'></img>
                                <p className='lipainfo'>Go to your Safaricom Mpesa<br /><i className='bold'>
                                </i><i className='bold'>Paybill: </i>522533<br />
                                    <i className='bold'>Account Number: </i>8007775<br />Enter payment<br />
                                    This transaction is absolutely FREE!<br />
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default services_offered