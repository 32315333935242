import React from 'react'
import Mchanga from '../mchanga.png'
function donate() {
    return (
        <div className='donate'>
            <div className='questions'>
                <details>
                    <summary className='q'>Dear Donor,</summary>
                    <p className='a'>We hope this message finds you in good health and high spirits. We are reaching out today with an urgent plea to stand together in support of our incredible first responders who dedicate their lives to keeping our communities safe. We have launched a powerful campaign with the goal of advocating for these brave individuals and we need your help to make a difference.<br />

                        Our campaign aims to raise awareness about the challenges faced by first responders and to provide them with the resources they desperately need. These selfless heroes often find themselves in high-stress situations, witnessing and experiencing traumatic events that can take a toll on their mental and emotional well-being. By donating to our campaign, even with the smallest contribution, you can play a vital role in helping us achieve our goals and provide much-needed support. <br />

                        Our message is simple: Every donation counts. We believe that when a community unites in support of a cause, no contribution is too small to make an impact. Your donation, no matter how modest, can help fund crucial programs and initiatives that directly benefit our first responders. From specialized counseling services to mental health workshops, your contribution will go a long way in providing the support they deserve.<br />

                        Imagine the positive ripple effect we can create together. By joining forces, we can ensure that our first responders receive the assistance they need to heal, recover, and build resilience. Your donation has the power to alleviate the burdens they carry and let them know that they are not alone in their journey.<br />

                        We understand that everyone's circumstances are different, and we value any amount you are able to contribute. Whether it's the cost of a cup of coffee or a small yearly pledge, your donation will make a difference. By giving what you can, you are sending a powerful message of solidarity and appreciation to our first responders, reminding them that their sacrifices do not go unnoticed.<br />

                        To donate, simply visit <a className='a pink' href='https://www.mchanga.africa/fundraiser/71703' target='_blank' rel="noopener noreferrer">This Link</a> and follow the quick and secure donation process. If you have any questions or need assistance, our dedicated team is ready to support you every step of the way.<br />

                        Please share this message with your friends, family, and colleagues, urging them to join this important cause. Together, let's create a wave of support that will uplift and inspire our first responders.<br />
                        Thank you for your generosity, compassion, and commitment to our first responders. Your donation will make a difference in their lives, ensuring they receive the support and recognition they deserve.<br />

                        With heartfelt gratitude.
                    </p>
                </details>
                <p className=' a bold'> Please support us Through:</p>
                <img className='mchangapic' src={Mchanga} alt='mchanga'></img><br />
                <a className='pink' href='https://www.mchanga.africa/fundraiser/71703' target='_blank' rel="noopener noreferrer">Click Here to Donate</a>
            </div>
        </div>
    )
}

export default donate