import React from 'react'
import Error from '../404-error.png';

function errorpage() {
  return (
    <div>
      <img src={Error} alt='error' className='error' /><t />
      <h1>Error 404!<br />Page Not Found!!!</h1>
    </div>
  )
}

export default errorpage