import React from 'react'

function Retreats() {
  return (
    <div className='retreats'>
      <h1>Retreats</h1>
      <p className='education'>Retreats are valuable experiences for first responders, providing opportunities for rest, rejuvenation, skill enhancement, and personal growth. Here are some key points about retreats for first responders:</p>
      <ol className='education' type='1.'>
        <li>Purpose: Retreats for first responders are designed to create a supportive and nurturing environment where participants can step away from their demanding roles and engage in self-care. Our purpose is to promote mental, emotional, and physical well-being, reducing stress and burnout.</li>
        <li>Skill enhancement and training: Our Retreats include workshops, training sessions, and educational activities aimed at enhancing the skills and knowledge of first responders. We cover topics such as stress management, mindfulness techniques, communication skills, resilience building, or other relevant areas that support personal and professional development.</li>
        <li>Team-building and collaboration:  Our Retreats include team-building activities and exercises that strengthen bonds among participants. These activities promote teamwork, effective communication, trust-building, and collaboration, which are essential for cohesive and efficient emergency response.</li>
      </ol>
    </div>
  )
}

export default Retreats