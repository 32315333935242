import React from 'react'
import Growing from "./event images/IMG-20231113-WA0010.jpg"

function tree_growing() {
    return (
        <div className='tree_growing'>
            <h1>TREE GROWING EXERCISE AT KYUMBI MACHAKOS</h1>
            <p>Kyumbi first responder's unit participating in tree planting exercise with the locals.</p>
            <img src={Growing} alt='img' /><br /> <br />
        </div>
    )
}

export default tree_growing