import React from 'react'
import Image1 from './event images/20230808_110219.jpg'
import Image2 from './event images/20230808_110227.jpg'
import Image3 from './event images/20230808_110232.jpg'

function Training() {
  return (
    <div className='training'>
      <h1>EMERGENCY DISPATCHERS INTENSIVE TRAINING PROGRAM</h1>
      <img src={Image1} alt='' />
      <p>"I congratulate the 30 officers from the County Government of Machakos who have today earned certificates as First Responders after undergoing a one week Emergency Dispatchers intensive training program to enhance their skills and expertise in saving lives and property in times of emergencies.
        <br />
        <img src={Image2} alt='' /><br />
        The officers including firefighters, medical officers, clinical officers and dispatch officers from our Emergency Operation Center at the People's Park were trained on emergency operations including receiving and documenting distress calls, dispatch and post-dispatch activities, stress management as well as medical and legal issues during emergencies.<br />

        We are glad that these officers will now be able to better protect themselves and the community in times of emergencies.

        I am grateful to our partners Malteser International who have provided the necessary resources and state-of-the art technology for enhanced communication and skills training.<br />

        Special thanks to County Secretary Dr. Muya Ndambuki, Devolution Executive, Dr. Consolata Mutisya, Chief Officer Emergency Services Col. Gideon Mbithi, Advisor Donor Coordination and Partnership, Samera Aziz, Director Emergency and Rescue services David Mwongela and the Training Coordinator Mr. Charles Mulinge for overseeing the event to completion.

        To the graduands, this is the beginning of a new chapter; to apply the knowledge gained during the training. Step out and demonstrate your expertise in responding to emergencies swiftly and efficiently. We wish you all the best as you save lives. You have the full support of my Government."<br />
        <b className='bold'>H.E. Hon. Wavinya Ndeti Oduwole,<br />Governor, Machakos County.</b></p>
      <img src={Image3} alt='' />

    </div>
  )
}

export default Training