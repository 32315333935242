import React from 'react'

function Tac() {
  return (
    <div className='tac'>
      <h1>Technology and Communication</h1>
      <div className='videos'>
        <iframe width="400" height="250" src="https://www.youtube.com/embed/Hutu_uDI6Z4" title="Drone As First Responder (DFR) Program Overview" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
      <div className='technology reveal'>
        <p>Technology plays a significant role in the field of first responders, enhancing their capabilities, improving communication, and aiding in emergency response. FRSNA aims to establish a new approach for disaster response in order to improve disaster resilience. This will be accomplished by the targeted and synergetic deployment of set of appropriate and complementary technologies. Here are some ways technology impacts first responders:</p>
        <ol type='1.'>
          <li>Communication and Connectivity: Technology provides first responders with reliable and efficient communication tools. They use two-way radios, mobile devices, and specialized communication systems to stay connected with their team members, dispatch centers, and other agencies involved in emergency response. This enables real-time information sharing, coordination, and collaboration during critical incidents</li>
          <li>Geographic Information Systems (GIS): GIS technology helps first responders access and analyze geographic data to better understand the emergency situation. It provides detailed maps, satellite imagery, and geospatial data that aid in planning response strategies, identifying optimal routes, locating resources, and visualizing potential hazards or vulnerable areas.</li>
          <li>Wearable Technology: Wearable devices, such as body-worn cameras, smartwatches, and biometric sensors, have become increasingly common among first responders. They capture real-time audio, video, and physiological data that can be used for evidence, situational awareness, and monitoring the well-being of personnel during operations.</li>
          <li>Unmanned Aerial Systems (UAS): Also known as drones, UAS technology has revolutionized various aspects of emergency response. Drones equipped with cameras and sensors can provide aerial views of incident scenes, assess damage, locate missing persons, and aid in search and rescue operations. They also enhance situational awareness without putting first responders at risk.</li>
          <li>Data Analytics and Predictive Modeling: Advanced data analytics and predictive modeling technologies allow first responders to analyze large volumes of data to identify patterns, forecast trends, and make informed decisions. These tools can be used to enhance emergency preparedness, resource allocation, risk assessment, and response planning.</li>
          <li>Virtual Reality (VR) and Augmented Reality (AR): VR and AR technologies have the potential to enhance training and simulation exercises for first responders. They provide realistic and immersive environments where responders can practice skills, simulate emergency scenarios, and improve decision-making in a safe and controlled setting.</li>
        </ol>
        <p>Our technology offers numerous benefits, its successful implementation requires proper training, data security measures, and ongoing maintenance. We advise and advocate for our first responders to adapt to the rapidly evolving technological landscape and stay updated on the latest advancements that can improve their effectiveness in emergency response.</p>
      </div>
    </div>
  )
}

export default Tac