import React, { useState } from 'react'
import "../App.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function Links() {
  const [about, setAbout] = useState(false);
  const [media, setMedia] = useState(false);
  const [program, setProgram] = useState(false);
  const [donate, setDonate] = useState(false);
  return (
    <div className='navbar'>
      <Navbar className='nav' expand="lg">
        <Container className='cont'>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className='lis' href="/">Home</Nav.Link>
              <NavDropdown className='lis' title="About" id="basic-nav-dropdown" show={about} onMouseEnter={() => setAbout(true)} onMouseLeave={() => setAbout(false)}>
                <NavDropdown.Item href="/background">Background</NavDropdown.Item>
                <NavDropdown.Item href="/about">Mission, Vision, and Values</NavDropdown.Item>
                <NavDropdown.Item href="/faqs">FAQs</NavDropdown.Item>
                <NavDropdown.Item href="/coming_soon">Testimonials</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className='lis' href="/services">Services Offered</Nav.Link>
              <NavDropdown className='lis' title="Media Centre" id="basic-nav-dropdown" show={media} onMouseEnter={() => setMedia(true)} onMouseLeave={() => setMedia(false)}>
                <NavDropdown.Item href="/publications">Publications</NavDropdown.Item>
                <NavDropdown.Item href="/speeches">Speeches and Newsletters</NavDropdown.Item>
                <NavDropdown.Item href="/videos">Photo, audio and Video Gallery</NavDropdown.Item>
                <NavDropdown.Item href="/books_and_articles">Books and Articles</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown className='lis' title="Programs" id="basic-nav-dropdown" show={program} onMouseEnter={() => setProgram(true)} onMouseLeave={() => setProgram(false)}>
                <NavDropdown.Item href="/education">Education</NavDropdown.Item>
                <NavDropdown.Item href="volunteer">Volunteer</NavDropdown.Item>
                <NavDropdown.Item href="/retreat">Retreats</NavDropdown.Item>
                <NavDropdown.Item href="/technology_and_communication">Technology and Communication</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown className='lis' title="Donors" id="basic-nav-dropdown" show={donate} onMouseEnter={() => setDonate(true)} onMouseLeave={() => setDonate(false)}>
                <NavDropdown.Item href="/donate" >Donate</NavDropdown.Item>
                <NavDropdown.Item href="/partner_with_us">Partner with Us</NavDropdown.Item>
                <NavDropdown.Item href="/coming_soon">Donor Response</NavDropdown.Item>
                <NavDropdown.Item href="/coming_soon">Evaluation of Our Work</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
function Navba() {
  return (
    <Links />
  );
}

export default Navba