import React from 'react'
import Pic1 from "./event images/7.jpeg"
import Pic2 from "./event images/8.jpeg"

function athiresponse() {
  return (
   <div className='athi'>
            <h1>MULTI-AGENCY RESPONSE(ATHI-RIVER)</h1>
            <img src={Pic1} alt='img' /><br /> <br />
            <p>The Machakos County Disaster Risk Management section at the Office of the Governor has embraced a multi-agency approach based on the international Incident Command System (ICS) to effectively respond to this and any other disaster situations that may arise within our county. This approach involves the coordination of all county departments, national government ministries and agencies, humanitarian organizations such as the Kenya Red Cross Society, donor partners, and civil society. Together, they form a synchronized response unit operating under the Machakos County Incidence Management system.</p>
            <img src={Pic2} alt='img'/><br /> <br />
            <p>The disaster risk management cycle is not a fully devolved function, it is a shared responsibility between the County and National governments. When fully devolved, we envision a future where all 47 counties in Kenya will adopt a standardized Disaster Response mechanism aligned with international standards. This will facilitate the seamless sharing and donation of resources, including expertise, among counties to effectively combat disasters.</p><br />
            <p>As of now at Machakos county we do what we can with the meagre financial resources and few equipment available to enable risk reduction, response and recovery. Many a time reaching out to Donors and partners in this cause.<br />
            Our emergency Machakos county response teams, under the leadership of Her Excellency Governor Wavinya Ndeti, have undergone extensive training at international levels. They have been trained in firefighting, handling hazardous materials, extrication at road traffic accident (RTA) sites and structural collapse scenes, as well as water rescue operations. We have full confidence in the dedication and capabilities of our county's response teams, and are certain if chanced with modern equipment, they can deliver exceptional performance in times of crisis.<br />
            This commitment stems from Her Excellency Wavinya Ndeti's passion for safeguarding the lives, properties, and livelihoods of our citizens. The result of her excellencies' effort can be felt as quick and quality response to incident scenes by the Machakos County emergency response units. <br />I extend my heartfelt thanks and blessings to all the volunteer first responders, firefighters, and response teams who selflessly risk their lives and well-being to save others. May God grant solace and patience to those affected and afflicted by disasters.May our wise leaders be blessed with long life and good heal enabling them to continue extending a helping hand that uplifts our co our nation during challenging times.<br />
            <b className='bold'>Mr Mohamed Shahid Moughal,<br />
          Advisor, Public Risk Management and Oversight,<br />Office Of the Governor, Machakos County.</b></p>
            </div>
  )
}

export default athiresponse