import React from 'react';
import logo from "../LOGO.png"
import hotline from "./events/event images/hotline_no.jpg"
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className='Home'>
            <Link to='/'><img className='image1' src={logo} alt='logo' /></Link>
            <div className='header'>
                <h1 className='title'>First Responders Support Network</h1><br />
                <h2>A FIRST RESPONDERS COMMUNITY ORGANIZATION</h2><br />
                <div className='links'>
                    <a href='mailto:frsninternational@gmail.com'><i>frsninternational@gmail.com</i></a>,
                    <a href='tel:+254704215539'><i>+254704215539,</i></a><br />
                    <img className='hotline' src={hotline} alt='logo' />
                </div>
                <h3>"TO ADVOCATE FOR FIRST RESPONDERS WELLNESS INITIATIVE, POLICIES, AND LEGISLATION"</h3>
            </div>
        </div>
    );
}

export default Home;