import React from 'react'
import Mission from './about'
import talking from "./events/event images/IMG-20231102-WA0101.jpg"
import governor from "./events/event images/IMG-20231106-WA0003.jpg"
import logo2 from "../LOGO2.png"
import Event1 from "./events/event images/20230808_110219.jpg"
import Event2 from "./events/event images/IMG-20230627-WA0011.jpg"
import Event3 from "./events/event images/20230808_110232.jpg"
import Event4 from "./events/event images/IMG-20231102-WA0044.jpg"
import Event5 from "./events/event images/IMG-20231102-WA0102.jpg"
import Event6 from "./events/event images/IMG-20231102-WA0088.jpg"
import Event7 from "./events/event images/IMG-20231102-WA0085.jpg"
import Event8 from "./events/event images/20230808_110227.jpg"
import Event9 from "./events/event images/IMG-20231030-WA0020.jpg"
import Event10 from "./events/event images/IMG-20231030-WA0022.jpg"
import Event11 from "./events/event images/IMG-20231030-WA0027.jpg"
import Event12 from "./events/event images/IMG-20231030-WA0027.jpg"
import Event13 from "./events/event images/IMG-20231109-WA0021.jpg"
import Event14 from "./events/event images/IMG-20231113-WA0009.jpg"
import Event15 from "./events/event images/6.jpeg"
import Event16 from "./events/event images/9.jpeg"
import Event17 from "./events/event images/NPD4.jpeg"

function about() {
  return (
    <div className='About'>
      <img className='Image' src={logo2} alt='logo' />
      <h1>First Responders Support Network International</h1>
      <img className='gov' src={governor} alt='logo' />
      <h6>Who is a First Responder?</h6>
      <div className='whois'>
        <p>A first responder is a person with specialized training who is among the first to arrive and provide assistance or incident resolution at the scene of an emergency, such as an <i>accident, disaster, medical emergency, structure fire, crime, or terrorist attack.</i>
        </p>
        <p>Through our mission, we strive to ensure that first responders receive the care and support they need, enabling them to maintain their well-being, navigate challenges, and continue to serve their communities with resilience and dedication.</p>
        <p>A first responder is someone whose job is to respond immediately (first) when there is an accident or emergency. Emergency Medical Technicians  (EMTs), paramedics, firefighters, and police officers are all considered first responders. <br />In the continent of Africa and especially in Kenya the first responders are forgotten once the emergency situation is over and therefore a conversation has to be started where there has never been one, conversations about their general wellbeing pre-emergency incident, at emergency incident, post-emergency incident and anxiety while on standby at stations in readiness to attend to emergencies and disaster intervention situations.<br /> Awareness campaigns have to be started to the fact that these first responders suffer silently from post-traumatic stress disorder (PTSD) and depression which is picked up from and encountered in their line of duty. These are trained professionals it affects their performance at work, social lives and family relationships, it is responsible for violent behavior toward co-workers, spouses, suicides, murders and irresponsible behavior.<br />There being none, standards, policies and legislation have to be set, made, passed and implemented. Creation of "First Responders Support Network" is necessary to advocate for first responders wellness initiative, policies and legislation.
        </p>
      </div>
      <div className='addons'>
        <div className='ll'>
          <Mission />
          <button className="button">
            <a href='/about'>
              Read More
            </a>
          </button>
        </div>
        <br />
        <br />
        <button className=" n">
          <a href='https://www.flickr.com/people/f1rstresponders/'>Click to View<br />
            GALLERY
          </a>
        </button>
        <br />
        <br />
        <h1>Events</h1>
        <div className='Event reveal'>
          <div className='event'>
            <img className='event-img' src={Event17} alt='' /><a href='/national_tree_planting_day' className='event-text'>National Tree Planting Day</a>
          </div>
          <div className='event'>
            <img className='event-img' src={Event16} alt='' /><a href='/multi_agency_responce_at_Athi-River' className='event-text'>Multi-Agency Response (Athi-river)</a>
          </div>
          <div className='event'>
            <img className='event-img' src={Event15} alt='' /><a href='/first_responders_team_on_the_ground' className='event-text'>First Responders Team on the ground</a>
          </div>
          <div className='event'>
            <img className='event-img' src={Event14} alt='' /><a href='/tree_growing' className='event-text'>Tree growing exercise at Kyumbi Machakos</a>
          </div>
          <div className='event'>
            <img className='event-img' src={Event13} alt='' /><a href='/sensitizing_the_public' className='event-text'>Sensitising the public on county emergency numbers in case of an emergency</a>
          </div>
          <div className='event'>
            <img className='event-img' src={talking} alt='' /><a href='/lecture' className='event-text'>A discussion on the need of the First Responders Support Network</a>
          </div>
          <div className='event'>
            <img className='event-img' src={Event3} alt='' /><a href='/training' className='event-text'>I congratulate the 30 officers from the County Government of Machakos who have today earned certificates as First Responders after undergoing a one week</a>
          </div>
        </div>
      </div>
      <div className='events'>
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img src={Event1} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event2} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event3} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event4} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event5} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event6} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event7} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event8} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event9} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event10} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event11} height="110" width="260" alt="" />
            </div>
            <div className="slide">
              <img src={Event12} height="110" width="260" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default about